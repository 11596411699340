jQuery(function() {
    initScrollEvent();
});

function initScrollEvent()
{
    $(window).on('scroll', function (event) {
        var scroll          = $(window).scrollTop();
        var headerHeight    = $('#header').height();
        if(scroll > headerHeight) {
            $('body').addClass('scrolling');
        } else {
            $('body').removeClass('scrolling');
        }
    });
}
